import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';

export const TIME_ZONE = 'Europe/Madrid';
export const calendarConfig = {
  appEmail: process.env.NEXT_PUBLIC_CLIENT_EMAIL!,
  timeZone: TIME_ZONE,
  // sam@adflow.se
  activeCalendarId:
    '7cf7b456039c297490bcd32fafa6e062d0f4c52b6570006155d733c60db49b62@group.calendar.google.com'
};
const configRef = doc(db, 'config', 'data');

interface Config {
  activeCalendarId: string;
}
export const getCalendarConfig = async () => {
  if (process.env.NODE_ENV !== 'production') return calendarConfig;
  const config = (await getDoc(configRef)).data() as Config;

  return { ...calendarConfig, activeCalendarId: config.activeCalendarId };
};

export const setCalendarConfigId = async (calendarId: string) => {
  await updateDoc(configRef, { activeCalendarId: calendarId });
};
