export const RECAPTCHA_CONTAINER_ID = 'recaptcha-container';

export const dateOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

export const CALENDAR_EVENT_DURATION_MINUTES = 15;

export const STAGES = {
  PICK_UP: 'pick-up',
  DROP_OFF: 'drop-off'
} as const;

export const CURRENCY = 'eur';

export const FILTERS = {
  WEB_APP: 'web-app',
  DROP_OFF_ONLY: 'drop-off-only'
};

export const LOCALE = 'sv-SE';

const priceMapDev = {
  covered: {
    basePriceId: 'prod_OaXL6uuaCDrtXj',
    perDayPriceId: 'prod_OaXLhGGZQ3M2kO'
  },
  motorhome: {
    basePriceId: 'prod_OaXKqVJV419bsC',
    perDayPriceId: 'prod_OaXII8CPGtwHEF'
  },
  outdoor: {
    basePriceId: 'prod_OaXL4ibPZiAm8Y',
    perDayPriceId: 'prod_OaXK7kwR5Zi3f1'
  },
  dropOffFee: 'prod_Owcp78q1ZUXFzu'
} as const;

const priceMapProd = {
  covered: {
    basePriceId: 'prod_OaXw46UGIXQOYm',
    perDayPriceId: 'prod_OaXyFSQBHPyHGA'
  },
  motorhome: {
    basePriceId: 'prod_OaXz1rNc7MYnrF',
    perDayPriceId: 'prod_OaXziZnnJcNUkr'
  },
  outdoor: {
    basePriceId: 'prod_OaXyKiDNsH9iLJ',
    perDayPriceId: 'prod_OaXzcV2aVEUlcG'
  },
  dropOffFee: 'prod_OwdMHMcFBv6gS4'
} as const;

export const priceMap =
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY?.includes('live')
    ? priceMapProd
    : priceMapDev;
