import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserDetails } from 'lib/types';

const useValue = () => {
  const [currentEventType, setCurrentEventType] = useState<
    'short' | 'contract' | undefined
  >();
  const [currentBookingDetails, setCurrentBookingDetails] = useState<
    Omit<UserDetails, 'specialRequests'>
  >({
    email: '',
    firstName: '',
    lastName: '',
    flightNumber: '',
    phoneNumber: '',
    registrationNumber: ''
  });
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

  return {
    currentEventType,
    setCurrentEventType,
    isPaymentProcessing,
    setIsPaymentProcessing,
    currentBookingDetails,
    setCurrentBookingDetails
  };
};

export const AppContext = createContext({} as ReturnType<typeof useValue>);

export const useAppContext = () => useContext(AppContext);

const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();

  useEffect(() => {
    window.navigate = (route) => router.push(route);
  }, [router]);

  return (
    <AppContext.Provider value={useValue()}>{children}</AppContext.Provider>
  );
};

export default AppContextProvider;
