import { getBrandConfig } from 'config/brands';
import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber as fireSignInWithPhoneNumber,
  signOut as fireSignOut,
  getAuth,
  signInWithPopup
} from 'firebase/auth';
import { QueryDocumentSnapshot, getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { RECAPTCHA_CONTAINER_ID } from 'lib/constants';

const provider = new GoogleAuthProvider();

export const converter = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T
});

// Initialize Firebase
const app = initializeApp(getBrandConfig().firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const firebaseAuth = getAuth(app);
export const signOut = () => {
  recaptchaVerifier = null;
  fireSignOut(firebaseAuth);
};

export let recaptchaVerifier: RecaptchaVerifier | null = null;
export const signInWithPhoneNumber = async (phoneNumber: string) => {
  if (!recaptchaVerifier)
    recaptchaVerifier = new RecaptchaVerifier(
      RECAPTCHA_CONTAINER_ID,
      {
        size: 'invisible'
      },
      firebaseAuth
    );
  return fireSignInWithPhoneNumber(
    firebaseAuth,
    phoneNumber,
    recaptchaVerifier
  );
};
export const signInWithGoogle = () => signInWithPopup(firebaseAuth, provider);
