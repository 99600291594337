import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WhatsAppHelper = () => {
  return (
    <div
      className="cursor-pointer"
      onClick={() => (window.location.href = '/help')}
    >
      <div className="bg-blue-500 rounded px-4 py-2 text-white flex items-center gap-4">
        <FontAwesomeIcon size="2x" icon={faWhatsapp as IconProp} />
        <div>
          <div className="text-sm font-semibold">Something unexpected?</div>
          <div className="text-sm">Contact us</div>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppHelper;
