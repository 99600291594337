import { getBrandConfig } from 'config/brands';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import AuthProviderLink from 'components/AuthProviderLink';
import SignIn from 'components/SignIn';
import { db, firebaseAuth } from 'lib/firebase';

const BASE_USER = {
  registrationNumbers: [],
  recentBookingDetails: {},
  accountLinkDeclined: false
};

export interface CustomUser extends User {
  recentBookingDetails: {
    registrationNumber: string;
    flightNumber: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  registrationNumbers: string[];
  accountLinkDeclined: boolean;
  isAdmin?: boolean;
}

const AuthContext = createContext<CustomUser | null>(null);

export const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<CustomUser | null>(null);
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    const { bookingId = '', bookingType = '' } = router.query;
    if (bookingId && bookingType) {
      router.push(`/booking/${bookingType}/${bookingId}`);
    }
  }, [router]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          await setDoc(docRef, {
            ...BASE_USER,
            isAppUser: !!window?.isInApp,
            notificationPushToken: window?.pushTokenFromRN ?? ''
          });
        }

        await updateDoc(docRef, {
          isAppUser: !!window?.isInApp
        });

        if (window?.isInApp) {
          await updateDoc(docRef, {
            notificationPushToken: window?.pushTokenFromRN ?? ''
          });
        }
        window?.ReactNativeWebView?.postMessage('LOGGED IN');
        setUser({ ...user, ...(docSnap.data() as CustomUser) });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <div className="grid h-screen place-items-center bg-red-500">
        <div>
          <Image
            priority
            src={`/${getBrandConfig().logo}`}
            alt={`/${getBrandConfig().name}`}
            width={300}
            height={108}
            className="bg-red-500"
          />
          <h1 className="text-white">SIGNING IN...</h1>
        </div>
      </div>
    );
  }

  if (!user && !router.pathname.includes('admin')) {
    return <SignIn />;
  }

  if (
    !window?.isInApp &&
    user &&
    !user?.accountLinkDeclined &&
    !user?.providerData.find((p) => p.providerId === 'google.com')
  ) {
    return <AuthProviderLink setUser={setUser} user={user} />;
  }

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
