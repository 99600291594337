/* eslint-disable @typescript-eslint/no-var-requires */
const demopark = require('./demo');
const scandpark = require('./scandpark');

const getBrandConfig = () => {
  // TODO: Add brand to env variable
  const brand = process.env.NEXT_PUBLIC_BRAND;

  switch (brand) {
    case 'scandpark':
      return scandpark;
    case 'demo':
      return demopark;
    default:
      return scandpark;
  }
};

module.exports = {
  getBrandConfig
};
