import { getBrandConfig } from 'config/brands';
import { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { Button, ThemeProvider } from '@material-tailwind/react';
import { ErrorBoundary } from '@sentry/nextjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import WhatsAppHelper from 'components/WhatsAppHelper';
import AppContextProvider from 'context/AppContext';
import AuthContextProvider from 'context/AuthContextProvider';
import ServicesCartContextProvider from 'context/ServicesCartContextProvider';
import { calendarConfig, getCalendarConfig } from '../lib/calendar-config';
import '../styles/globals.css';
import '../styles/spinner.css';

config.autoAddCss = false;

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => typeof page;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const theme = {
  dialog: {
    styles: {
      sizes: {
        xl: {
          width: 'w-[90vw]',
          minWidth: 'min-w-[25%]',
          maxWidth: 'max-w-[600px]',
          padding: 'p-4'
        }
      }
    }
  }
};

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } }
});

const AppContext = createContext<{
  appEmail: string;
  timeZone: string;
  activeCalendarId: string;
  refetchConfig: () => Promise<void>;
  loading: boolean;
}>({
  ...calendarConfig,
  activeCalendarId: '',
  refetchConfig: () => Promise.resolve(),
  loading: false
});
export const useAppContext = () => useContext(AppContext);

const ConfigLoader = ({
  refetchConfig,
  setLoading
}: {
  refetchConfig: () => Promise<void>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      console.log(
        'Production release version:',
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
      );
    }

    setLoading(true);
    refetchConfig().finally(() => {
      setLoading(false);
    });
  }, [refetchConfig, setLoading]);

  return null;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const isZoomable = !!router.query?.fullscreen;

  const [loading, setLoading] = useState(true);
  const getLayout = Component.getLayout ?? ((page) => page);

  const [configuration, setConfiguration] = useState({
    ...calendarConfig,
    activeCalendarId: ''
  });

  const refetchConfig = useCallback(async () => {
    return getCalendarConfig().then((data) => {
      setConfiguration(data);
    });
  }, []);

  return (
    <>
      <Head>
        <title>{getBrandConfig().title}</title>
        {!isZoomable && (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
        )}
        <link rel={`/${getBrandConfig().name}`} href="/favicon.png" />
      </Head>
      <ErrorBoundary
        fallback={
          <div className="grid gap-2 m-auto max-w-md p-2">
            <div className="bg-red-500 p-4 rounded text-white">
              For some reason the app has crashed. Try reloading or contact us
              via WhatsApp or email if the problem persists.
            </div>
            <Button onClick={() => (window.location.href = '/')}>Reload</Button>
            <WhatsAppHelper />
          </div>
        }
      >
        <ConfigLoader {...{ refetchConfig, setLoading }} />
        <ThemeProvider value={theme}>
          <QueryClientProvider client={queryClient}>
            <AppContext.Provider
              value={{ ...configuration, refetchConfig, loading }}
            >
              <AuthContextProvider>
                <AppContextProvider>
                  <ServicesCartContextProvider>
                    {!configuration.activeCalendarId &&
                    !router.pathname.includes('admin') &&
                    !loading ? (
                      <div className="grid place-items-center">
                        <h1 className="p-4 text-center">
                          Could not reach {getBrandConfig().name} services. You
                          might be offline. Please check your Internet
                          connection and retry.
                        </h1>
                        <Button onClick={() => (window.location.href = '/')}>
                          Retry
                        </Button>
                      </div>
                    ) : (
                      getLayout(<Component {...pageProps} />)
                    )}
                  </ServicesCartContextProvider>
                </AppContextProvider>
              </AuthContextProvider>
            </AppContext.Provider>
          </QueryClientProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

export default appWithTranslation(App);
