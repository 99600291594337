const firebaseConfig = {
  apiKey: 'AIzaSyDR_YaJ_Rg-GJuBHICZuKoKO0q-oD4NaA8',
  authDomain: 'scand-park.firebaseapp.com',
  projectId: 'scand-park',
  storageBucket: 'scand-park.appspot.com',
  messagingSenderId: '423655395669',
  appId: '1:423655395669:web:3dcc559347c509ac225fdc',
  measurementId: 'G-LXD0M1FDDN'
};

const config = {
  firebaseConfig,
  firebaseClientEmail:
    'firebase-adminsdk-eljmx@scand-park.iam.gserviceaccount.com',
  colors: {
    primary: '#E20612'
  },
  name: 'ScandPark',
  logo: 'logo-scandpark-white.png',
  title: 'ScandPark',
  productionURL: 'https://book.scandpark.eu', // NO TRAILING SLASH ALLOWED
  companyURL: 'https://www.scandpark.eu',
  supportNumber: '+34 693 751 684',
  supportEmail: 'info@scandpark.eu',
  adress: 'Camino Puente del Rey, 1, 29140 Malaga'
};

module.exports = config;
