import { useEffect, useRef, useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const COUNTRY_LIST_URL =
  'https://gist.githubusercontent.com/DmytroLisitsyn/1c31186e5b66f1d6c52da6b5c70b12ad/raw/2bc71083a77106afec2ec37cf49d05ee54be1a22/country_dial_info.json';

type Country = { name: string; flag: string; dial_code: string; code: string };

interface Props {
  onChange: (countryCode: string) => void;
}

const CountryPicker = ({ onChange }: Props) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [countries, setCountries] = useState<Record<string, Country>>({});
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      searchRef.current?.focus();
    }

    return () => {
      setSearchTerm('');
    };
  }, [open]);

  useEffect(() => {
    if (selectedCountryCode) return;

    (async () => {
      const ipInfo = await fetch(
        `https://ipinfo.io/json?token=${process.env.NEXT_PUBLIC_IPINFO_TOKEN}`
      ).then((r) => r.json());

      setSelectedCountryCode(ipInfo.country || '');

      const data: Country[] = await fetch(COUNTRY_LIST_URL).then((r) =>
        r.json()
      );

      const countryMap = Object.fromEntries(
        data.map((item) => [item.code, item])
      );
      setCountries(countryMap);
      onChange(countryMap?.[ipInfo.country]?.dial_code || '');
    })();
  }, [onChange, selectedCountryCode]);

  return (
    <div className="flex items-center pr-4">
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="flex items-center"
      >
        <span className="mr-1 text-2xl">
          {countries?.[selectedCountryCode]?.flag ||
            Object.values(countries)?.[0]?.flag}
        </span>
        <div
          className="mr-1"
          style={{
            width: 0,
            height: 0,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            borderTop: '4px solid #2f2f2f',
            fontSize: 0
          }}
        />
        <span className="text-lg">
          {countries?.[selectedCountryCode]?.dial_code ||
            Object.values(countries)?.[0]?.dial_code}
        </span>
      </button>
      {open && (
        <div className="fixed inset-0 z-10 bg-gray-900 p-4 text-white">
          <div className="m-auto flex h-full max-w-lg flex-col">
            <button onClick={() => setOpen(false)} className="ml-auto">
              <FontAwesomeIcon size="sm" icon={faClose} />
            </button>
            <h3 className="mb-2 text-lg">Select phone country</h3>
            <div className="mb-4 flex gap-2 border-0 border-b border-b-white text-white">
              <input
                name="x"
                ref={searchRef}
                placeholder="Search"
                className="w-full bg-transparent p-2"
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              <button
                type="button"
                onClick={() => setSearchTerm('')}
                className="text-sm text-blue-gray-400"
              >
                Cancel
              </button>
            </div>
            <div className="overflow-auto">
              {Object.values(countries)
                .filter(
                  (item) =>
                    item.name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    item.dial_code
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    item.code.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map(({ dial_code, flag, name, code }) => (
                  <button
                    onClick={() => {
                      onChange(dial_code);
                      setSelectedCountryCode(code);
                      setOpen(false);
                    }}
                    className="block w-full border-b border-gray-800 p-3 text-left"
                    key={name}
                  >
                    {flag} {name} ({dial_code})
                  </button>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryPicker;
