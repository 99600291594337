import React, { createContext, useContext, useState } from 'react';

export interface CartItem {
  id: string;
  label: string;
  price: number;
  description: string;
  imageURL: string;
  quantity: number;
}

const useValue = () => {
  const [cart, setCart] = useState<CartItem[]>([]);

  return {
    cart,
    setCart
  };
};

export const ServicesCartContext = createContext(
  {} as ReturnType<typeof useValue>
);

export const useServiceContext = () => useContext(ServicesCartContext);

const ServicesCartContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return (
    <ServicesCartContext.Provider value={useValue()}>
      {children}
    </ServicesCartContext.Provider>
  );
};

export default ServicesCartContextProvider;
