import { getBrandConfig } from 'config/brands';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { ConfirmationResult } from 'firebase/auth';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, Input } from '@material-tailwind/react';
import CountryPicker from 'components/CountryPicker';
import { RECAPTCHA_CONTAINER_ID } from 'lib/constants';
import { signInWithGoogle, signInWithPhoneNumber } from 'lib/firebase';

const LS_PHONE_NUMBER = 'phoneNumber';
const APP_URL = getBrandConfig().productionURL;

const SignIn = () => {
  const isGoogleSignInFromApp =
    window.location.href.includes('OPEN_IN_BROWSER');
  const inputRef = useRef<HTMLInputElement>(null);
  const [countryCode, setCountryCode] = useState('');
  const [value, setValue] = useState(localStorage.getItem('phoneNumber') ?? '');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmFn, setConfirmFn] = useState<ConfirmationResult | null>(null);
  const [noPhone, setNoPhone] = useState(isGoogleSignInFromApp);

  const initPhoneSignIn = async () => {
    setError('');
    try {
      localStorage.setItem(LS_PHONE_NUMBER, value);

      const confirmationResult = await signInWithPhoneNumber(
        countryCode + value
      );
      setValue('');
      setConfirmFn(confirmationResult);
    } catch (error: any) {
      console.log(error);

      if (error?.code?.includes('invalid-phone-number')) {
        setError('The phone number provided could not be found');
        return;
      }
      if (error?.code?.includes('auth/too-many-requests')) {
        setError(
          'You have tried to sign in too many times in a short period of time. Please wait a few minutes and try again.'
        );
        return;
      }

      setError('Something went wrong, try to reload the application');
    }
  };

  useEffect(() => {
    if (confirmFn) {
      inputRef.current?.focus();
    }
  }, [confirmFn]);

  const emailSignIn = (
    <div className="grid gap-2 w-full">
      <Button
        size="lg"
        color="red"
        type="button"
        onClick={() => {
          setLoading(true);
          setError('');
          signInWithGoogle()
            .catch(() => {
              setError('Invalid code');
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        Sign in with Google
      </Button>
      <Button variant="outlined" onClick={() => setNoPhone(false)}>
        Back
      </Button>
    </div>
  );

  const phoneSignIn = (
    <>
      {confirmFn ? (
        <>
          <Input
            inputRef={inputRef}
            type="number"
            pattern="[0-9]*"
            label="Verification code"
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
          {loading && <div>Verifying...</div>}
          {error && (
            <p className="rounded bg-red-500 p-2 text-sm text-white">{error}</p>
          )}
          <Button
            color="red"
            type="button"
            onClick={() => {
              setLoading(true);
              setError('');
              confirmFn
                .confirm(value)
                .catch(() => {
                  setError('Invalid code');
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            Sign in
          </Button>
          <Button
            onClick={() => {
              setConfirmFn(null);
              setError('');
              setValue(localStorage.getItem('phoneNumber') ?? '');
            }}
          >
            Back
          </Button>
        </>
      ) : (
        <>
          <div className="mb-4 flex border-b border-b-red-500">
            <CountryPicker onChange={(value) => setCountryCode(value)} />
            <input
              className="text-lg"
              value={value}
              onChange={(event) => setValue(event.target.value)}
            />
          </div>
          {error && (
            <p className="w-10/12 rounded bg-red-500 p-2 text-sm text-white">
              {error}
            </p>
          )}
          <IconButton
            id={RECAPTCHA_CONTAINER_ID}
            size="lg"
            color="red"
            type="button"
            onClick={initPhoneSignIn}
            className="rounded-full p-8 shadow-lg"
          >
            <FontAwesomeIcon size="xl" icon={faArrowRight} />
          </IconButton>
        </>
      )}
      <div className="mt-8 grid place-items-center text-center">
        <p>Unable to sign in with phone number?</p>
        {window?.isInApp ? (
          <p className="text-sm">
            Use the{' '}
            <a className="text-blue-800" href={`${APP_URL}#OPEN_IN_BROWSER`}>
              browser version
            </a>{' '}
            to sign in with a Google account
          </p>
        ) : (
          <Button onClick={() => setNoPhone(true)} variant="text">
            Click here
          </Button>
        )}
      </div>
    </>
  );

  return (
    <>
      <main className="grid h-screen place-items-center">
        <div className="grid place-items-center gap-2">
          <div className="mb-4 rounded-lg bg-red-500 p-4">
            <Image
              priority
              src={`/${getBrandConfig().logo}`}
              alt={getBrandConfig().name}
              width={300}
              height={108}
            />
          </div>
          {noPhone ? emailSignIn : phoneSignIn}
        </div>
      </main>
    </>
  );
};

export default SignIn;
