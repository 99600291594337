import { getBrandConfig } from 'config/brands';
import Image from 'next/image';
import React, { useState } from 'react';
import { GoogleAuthProvider, linkWithPopup } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { Button } from '@material-tailwind/react';
import { CustomUser } from 'context/AuthContextProvider';
import { db, firebaseAuth } from 'lib/firebase';

const AuthProviderLink = ({
  user,
  setUser
}: {
  user: CustomUser;
  setUser: React.Dispatch<React.SetStateAction<CustomUser | null>>;
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const declineAccountLink = async () => {
    if (!firebaseAuth.currentUser) return;

    const ref = doc(db, 'users', user.uid);

    await updateDoc(ref, {
      accountLinkDeclined: true
    });

    firebaseAuth.updateCurrentUser(firebaseAuth.currentUser);
    setUser((state) => state && { ...state, accountLinkDeclined: true });
  };

  const linkAccount = () => {
    if (!firebaseAuth.currentUser) return;

    const provider = new GoogleAuthProvider();

    linkWithPopup(firebaseAuth.currentUser, provider)
      .then(() => {
        // Accounts successfully linked.
        setUser((state) => state && { ...state, accountLinkDeclined: false });
      })
      .catch((error) => {
        console.log({ error });
        setErrorMessage(
          error?.code === 'auth/credential-already-in-use'
            ? 'The selected account already exists, link not possible.'
            : 'For unknown reasons we can not link your account'
        );
      });
  };

  return (
    <div className="p-4">
      <div className="grid place-items-center gap-2">
        <div className="flex justify-center mb-4 w-full rounded-lg bg-red-500 p-4">
          <Image
            className="m-auto"
            priority
            src={`/${getBrandConfig().logo}`}
            alt={getBrandConfig().name}
            width={200}
            height={100}
            objectFit="contain"
          />
        </div>
      </div>
      <h1 className="text-center mb-4">Welcome to {getBrandConfig().name}!</h1>
      <p className="bg-yellow-200 border-l-4 border-l-yellow-900 p-3 text-sm mb-2">
        In case you should lose your phone, connect a Google account to access
        your account
      </p>
      <div className="grid gap-2">
        <Button className="h-24" color="red" size="lg" onClick={linkAccount}>
          Connect Google account
        </Button>
        <Button onClick={declineAccountLink} color="gray" variant="outlined">
          No thanks
        </Button>
      </div>
      {errorMessage && (
        <p className="mt-4 p-4 bg-red-500 text-white rounded font-bold">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default AuthProviderLink;
